@tailwind base;
@tailwind components;
@tailwind utilities;

.card-zoom:hover .card-zoom-image {
  @apply scale-110;
}
.card-zoom:hover .card-zoom-content {
  @apply scale-100 block;
}

* {
  @apply appearance-none outline-none;
}
*:focus {
  @apply appearance-none outline-none;
}

/* src/App.css */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.backdrop-cover {
  -webkit-backdrop-filter: blur(100px);
  filter: blur(100px);
}

/* Hide the up and down arrows in Chrome, Safari, and Edge */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the up and down arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
